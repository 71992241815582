<template>
  <div>
    <b-card
      v-if="dataList.length"
      title="Duyurular"
    >
      <b-list-group>
        <b-list-group-item
          v-for="item in dataList"
          :key="item.id"
          button
          @click="openModal(item)"
        >
          <div class="d-flex justify-content-start align-items-center">
            <FeatherIcon
              icon="BellIcon"
              class="mr-1 text-danger"
            />
            <div>
              <div class="font-weight-bold text-danger">
                {{ item.title }}
              </div>
              <div
                v-if="item.modified"
                class="font-italic font-small-3 text-muted"
              >
                {{ moment(item.modified).format('llll') }} tarihinde güncellendi.
              </div>
              <div
                v-else
                class="font-italic font-small-2 text-muted"
              >
                {{ moment(item.created).format('llll') }} tarihinde oluşturuldu.
              </div>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <b-modal
      v-model="modalStatus"
      centered
      size="lg"
      hide-footer
      title="Duyuru"
    >
      <div>
        <div class="font-weight-bold text-danger font-medium-3">
          {{ modalData.title }}
        </div>
        <div>
          <div v-html="modalData.content" />
        </div>
        <div
          v-if="modalData.attachment"
          class="text-center"
        >
          <b-button
            variant="success"
            :href="baseURL + '/media/announcements/' + modalData.attachment"
            target="_blank"
          >
            <FeatherIcon icon="DownloadIcon" />
            Dosya İndir
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Announcements',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      modalStatus: false,
      modalData: {
        id: null,
        title: null,
        content: null,
        attachment: null,
        created: null,
        modified: null,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['announcements/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    openModal(data) {
      this.modalStatus = true
      this.modalData = data
    },
    getDataList() {
      this.$store.dispatch('announcements/getDataList', {
        select: [
          'announcements.id AS id',
          'announcements.title AS title',
          'announcements.content AS content',
          'announcements.attachment AS attachment',
          'announcements.created AS created',
          'announcements.modified AS modified',
        ],
        where: {
          'announcements.status': '1',
        },
      })
    },
  },
}
</script>
