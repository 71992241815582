<template>
  <div>
    <b-table-simple
      caption-top
      responsive
      bordered
      striped
      hover
      fixed
      class="font-small-2"
    >
      <b-thead>
        <b-tr>
          <b-th
            v-for="(header,key) in installmentsV2.header"
            :key="key"
            class="font-small-2 text-nowrap"
          >
            {{ header }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody
        v-for="(item,ikey) in installmentsV2.body"
        :key="ikey"
      >
        <b-tr>
          <b-th class="text-nowrap">
            {{ item.title }}
          </b-th>
          <b-td
            v-for="(row,bkey) in item.installments"
            :key="bkey"
            class="text-nowrap"
          >
            <b-form-radio
              v-if="row.text"
              v-model="form.selectInstallment"
              :value="item.id_pos_configs + '_' + row.installment + '_' + row.rate"
              name="installment"
            >
              <template v-if="row.installment > 0">
                <div class="font-small-2 font-weight-bolder">
                  {{ (calcInstallment(row.rate) / row.installment) | toCurrency }} ₺
                </div>
                <div class="text-danger font-small-2">
                  {{ row.text }}
                </div>
              </template>
              <template v-else>
                <div class="font-small-2 font-weight-bolder">
                  {{ calcInstallment(row.rate) | toCurrency }} ₺
                </div>
                <div class="text-danger font-small-2">
                  Tek Çekim
                </div>
              </template>
            </b-form-radio>
            <div v-else>
              -
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd, BFormRadio,
} from 'bootstrap-vue'

export default {
  name: 'InstalmentVue',
  components: {
    BTableSimple, BThead, BTbody, BTr, BTh, BTd, BFormRadio,
  },
  data() {
    return {
      selectInstallment: null,
    }
  },
  computed: {
    form() {
      return this.$store.getters['payment/getPaymentForm']
    },
    installments() {
      return this.$store.getters['posConfigs/getInstallments']
    },
    installmentsV2() {
      return this.$store.getters['posConfigs/getInstallmentsV2']
    },
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        const selectData = val.selectInstallment.split('_')
        const idPosConfigs = selectData[0]
        const installment = selectData[1]
        const rate = selectData[2]
        if (this.form.id_pos_configs !== idPosConfigs) {
          this.getPosConfig(idPosConfigs)
        }
        this.form.id_pos_configs = idPosConfigs
        this.form.installment = installment
        if (this.form.amount > 0) {
          this.form.pay_amount = this.calcInstallment(rate)
        }
      },
    },
  },
  methods: {
    calcInstallment(rate) {
      const rateFloat = parseFloat(rate)
      const amount = parseFloat(this.form.amount)
      let result = amount
      if (amount) {
        if (rateFloat > 0) {
          result = amount + (amount * rateFloat) / 100
        }
      }
      return result.toFixed(2)
    },
    getPosConfig(id) {
      this.$store.dispatch('posConfigs/pos_configPay3d', id)
    },
  },
}
</script>

<style scoped></style>
