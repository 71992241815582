<template>
  <b-card title="Firma Bilgileri">
    <b-list-group>
      <list-item
        title="Firma Adı"
        :value="dataItem.company"
      />
      <list-item
        title="Vergi No"
        :value="dataItem.tax_number"
      />
      <list-item
        title="Vergi Dairesi"
        :value="dataItem.tax_office"
      />
      <list-item
        title="Telefon"
        :value="dataItem.phone"
      />
      <list-item
        title="Adres"
        :value="dataItem.address"
      />
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup,
} from 'bootstrap-vue'
import ListItem from '@/views/Customers/View/ListItem.vue'

export default {
  name: 'CustomerInfo',
  components: {

    BCard,
    BListGroup,
    ListItem,
  },
  computed: {
    dataItem() {
      return this.$store.getters['payment/getCompany']
    },
  },
}
</script>

<style scoped>

</style>
